import {VacancyItemType} from "./Features/VacanciesPage/VacancyItem/VacancyItem";

export const vacancies: Array<VacancyItemType> = [
    { id: '1', position: 'Слесарь МСР', minSalary: undefined, salary: 47000, description: 'Сборка автокомпонентов', requirements: 'Рассматриваем мужчин и женщин, с опытом работы', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание (или компенсация) и компенсация проезда"], place: 'Димитровград', publicationDate: '06.12.2023' },
    { id: '2', position: 'Водитель погрузчика', minSalary: undefined, salary: 80000, description: 'Перемещать шпон от сушильных линий к месту назначения, подвозить и укладывать поддоны на лущильные линии. Осуществлять погрузку отходов в контейнера и на автомашины. Передвижение на погрузчике производить согласно установленной схеме маршрута, подвозить и укладываеть поддоны, осуществлять перевозку грузов', requirements: 'Опыт работы на погрузчике. Тракторные права', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание (или компенсация) и компенсация проезда"], place: 'Галич', publicationDate: '25.08.2023' },
    { id: '3', position: 'Слесарь МСР', minSalary: undefined, salary: 48000, description: 'Сборка автокомпонентов', requirements: 'Без опыта работы.', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Корпоративный транспорт"], place: 'Тольятти', publicationDate: '25.08.2023' },
    { id: '4', position: 'Сварщик МКС', minSalary: undefined, salary: 55000, description: 'Сварка деталей на машинах контактной сварки', requirements: 'Опыт работы сварщиком', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Корпоративный транспорт"], place: 'Тольятти', publicationDate: '25.08.2023' },
    { id: '5', position: 'Уборщик', minSalary: undefined, salary: 68000, description: 'Обеспечивать порядок в цеху и на территории', requirements: 'Без опыта, есть обучение', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание (или компенсация) и компенсация проезда"], place: 'Галич', publicationDate: '06.12.2023' },
    { id: '6', position: 'Слесарь ремонтник', minSalary: undefined, salary: 76000, description: 'Работа в ремонтной бригаде', requirements: 'Опыт работы. Умение читать пневмо и гидросхемы, иметь навыки ремонта узлов и агрегатов, ремонт гидронасосов', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Корпоративный транспорт"], place: 'Тольятти', publicationDate: '06.12.2023' },
    { id: '7', position: 'Прессовщик', minSalary: undefined, salary: 48000, description: 'Работа с резиной на прессах', requirements: 'Рассматриваем мужчин и женщин, без опыта работы', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание (или компенсация) и компенсация проезда"], place: 'Димитровград', publicationDate: '13.12.2023' },
    { id: '8', position: 'Станочник широкого профиля', minSalary: undefined, salary: 71000, description: 'Работа на токарных п/а Shutte, КСП, DAMF, шлифовальных станках разного типа SASL, ВВ, ВШ, 3Г, ОШ, BUA, агрегатных станках SAS, ХА. Работа на металлообрабатывающем оборудовании (агрегатно-сверлильные станки, шестишпиндельные токарные автоматы, одношпиндельные токарные станки)', requirements: 'Рассматриваем без опыта, обучение', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание"], place: 'Ярославль', publicationDate: '13.12.2023' },
    { id: '9', position: 'Формовщик машинной формовки', minSalary: undefined, salary: 71000, description: 'Изготовление форм для сложных отливок на формовочных машинах грузоподъёмностью до 1200 кг', requirements: 'Рассматриваем мужчин и женщин без опыта работы', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание"], place: 'Ярославль', publicationDate: '13.12.2023' },
    { id: '10', position: 'Слесарь МСР', minSalary: undefined, salary: 71000, description: 'Сборка, регулировка и испытание сложных узлов агрегатов, машин и станков. Слесарная обработка и пригонка крупных деталей и сложных узлов', requirements: 'Рассматриваем мужчин и женщин без опыта работы', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание"], place: 'Ярославль', publicationDate: '13.12.2023' },
    { id: '11', position: 'Заливщик металла', minSalary: undefined, salary: 71000, description: 'Рафинировка металла в раздаточной печи. Покраска прессформы. Заливка сплавов из раздаточной печи ковшом в прессформы', requirements: 'Рассматриваем мужчин и женщин без опыта работы', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание"], place: 'Ярославль', publicationDate: '13.12.2023' },
    { id: '12', position: 'Фрезеровщик', minSalary: undefined, salary: 157000, description: 'Выполнение технологической операции фрезерования поверхностей заготовок деталей с точностью размеров, в соответствии c технической документацией. Поддержка требуемого технического состояния технической оснастки (приспособлений и вспомогательных инструментов), размещенной на рабочем месте фрезеровщика', requirements: 'Опыт работы', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание"], place: 'Калуга', publicationDate: '13.12.2023' },
    { id: '13', position: 'Токарь расточник', minSalary: undefined, salary: 157000, description: 'Toкарнaя обpабoтка дeтaлей нa тoкapнo-pастoчныx станкaх', requirements: 'Опыт работы', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание"], place: 'Калуга', publicationDate: '13.12.2023' },
    { id: '14', position: 'Слесарь МСР', minSalary: undefined, salary: 125000, description: 'Сборка вагонов с нуля, перегородки обшивка и т.д.', requirements: 'Рассматриваем мужчин и женщин с опытом работы. Необходимо уметь читать чертежи', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание"], place: 'Калуга', publicationDate: '13.12.2023' },
    // { id: '1', position: 'Слесарь МСР', minSalary: 47000, salary: 68000, description: 'Сборка автокомпонентов', requirements: 'Рассматриваем мужчин и женщин, с опытом работы', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание (или компенсация) и компенсация проезда"], place: 'Димитровград', publicationDate: '06.12.2023' },
    // { id: '2', position: 'Литейщик', minSalary: undefined, salary: 67000, description: 'Прием деталей от автомата литья пластмассы. Визуальное определение брака литья. Укладка и упаковка деталей.', requirements: 'Рассматриваем мужчин и женщин, без опыта работы', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание (или компенсация) и компенсация проезда"], place: 'Димитровград', publicationDate: '06.12.2023' },
    // { id: '3', position: 'Водитель погрузчика', minSalary: undefined, salary: 80000, description: 'Перемещать шпон от сушильных линий к месту назначения, подвозить и укладывать поддоны на лущильные линии. Осуществлять погрузку отходов в контейнера и на автомашины. Передвижение на погрузчике производить согласно установленной схеме маршрута, подвозить и укладываеть поддоны, осуществлять перевозку грузов', requirements: 'Опыт работы на погрузчике. Тракторные права', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание (или компенсация) и компенсация проезда"], place: 'Галич', publicationDate: '25.08.2023' },
    // { id: '4', position: 'Слесарь МСР', minSalary: undefined, salary: 48000, description: 'Сборка и обработка авто компонентов', requirements: 'Без опыта работы.', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Корпоративный транспорт"], place: 'Тольятти', publicationDate: '25.08.2023' },
    // { id: '5', position: 'Сварщик МКС', minSalary: undefined, salary: 55000, description: 'Сварка деталей на машинах контактной сварки', requirements: 'Опыт работы сварщиком', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Корпоративный транспорт"], place: 'Тольятти', publicationDate: '25.08.2023' },
    // { id: '6', position: 'Штамповщик', minSalary: undefined, salary: 67000, description: 'Производить холодную штамповку простых и средней сложности деталей из металла различного профиля', requirements: 'Рассматриваем мужчин и женщин, с опытом работы на любой штамповке и любом производстве', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание (или компенсация) и компенсация проезда"], place: 'Димитровград', publicationDate: '06.12.2023' },
    // { id: '7', position: 'Токарь универсал', minSalary: 79000, salary: 129000, description: 'Выполнение работ на универсальном токарно станке. ДИП 200', requirements: 'Требуется 5-6 разряд. Опыт работы токарем', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляем проживание (или компенсация) и компенсация проезда"], place: 'Воронеж', publicationDate: '06.12.2023' },
    // { id: '8', position: 'Уборщик', minSalary: undefined, salary: 68000, description: 'Обеспечивать порядок в цеху и на территории', requirements: 'Без опыта, есть обучение', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание (или компенсация) и компенсация проезда"], place: 'Галич', publicationDate: '06.12.2023' },
    // { id: '9', position: 'Отделочник универсал', minSalary: undefined, salary: 55000, description: 'Работа по шпаклевке и отделке помещения', requirements: 'Опыт работы', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Корпоративный транспорт"], place: 'Тольятти', publicationDate: '06.12.2023' },
    // { id: '10', position: 'Слесарь ремонтник', minSalary: undefined, salary: 76000, description: 'Работа в ремонтной бригаде', requirements: 'Опыт работы', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Корпоративный транспорт"], place: 'Тольятти', publicationDate: '06.12.2023' },
    // { id: '11', position: 'Транспортировщик', minSalary: undefined, salary: 48000, description: 'Работа в цеху, подвозить контейнера на электророхле', requirements: 'Опыт работы, корочки на электроштабелер', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Корпоративный транспорт"], place: 'Тольятти', publicationDate: '06.12.2023' },
    // { id: '2', position: 'Токарь ДИП 200', minSalary: undefined, salary: 105000, description: 'Выполнение работ на универсальном токарно-винторезном станке ДИП 200', requirements: 'Требуется 5-6 разряд. Опыт работы токарем', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание (или компенсация) и компенсация проезда"], place: 'Воронеж', publicationDate: '05.06.2023' },
    // { id: '3', position: 'Фрезеровщик', minSalary: undefined, salary: 105000, description: 'Выполнение работ на универсальном токарно-винторезном станке', requirements: 'Требуется 5-6 разряд. Опыт работы фрезеровщиком', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание (или компенсация) и компенсация проезда"], place: 'Воронеж', publicationDate: '10.05.2023' },
    // { id: '4', position: 'Токарь расточник', minSalary: undefined, salary: 110000, description: 'Выполнение работ на универсальном токарно-винторезном станке', requirements: 'Требуется 5-6 разряд. Опыт работы фрезеровщиком', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание (или компенсация) и компенсация проезда"], place: 'Воронеж', publicationDate: '10.05.2023' },
    // { id: '5', position: 'Токарь расточник', minSalary: undefined, salary: 95000, description: 'Обработка деталей средней сложности на универсальных и координатно-расточных станках', requirements: 'Опыт работы токарем расточником', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Доставка на смену и со смены корпоративным транспортом", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание (или компенсация) и компенсация проезда"], place: 'Самара', publicationDate: '06.05.2023' },
    // { id: '15', position: 'Слесарь МСР', minSalary: 45000, salary: 75000, description: 'Сборка автомобильных фар и фонарей', requirements: 'Рассматриваем мужчин и женщин, без опыта работы', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание (или компенсация) и компенсация проезда"], place: 'Димитровград', publicationDate: '06.05.2023' },
    // { id: '16', position: 'Литейщик', minSalary: 45000, salary: 72000, description: 'Прием деталей от автомата литья пластмассы. Визуальное определение брака литья. Укладка и упаковка деталей.', requirements: 'Рассматриваем мужчин и женщин, без опыта работы', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание (или компенсация) и компенсация проезда"], place: 'Димитровград', publicationDate: '06.05.2023' },
    // { id: '19', position: 'Сверловщик', minSalary: undefined, salary: 57000, description: 'Нарезка отверстий разного типа, обработка пазов, уступов, наклонных, горизонтальных и вертикальных поверхностей деталей', requirements: 'Опыт работы на сверлильных станках', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Доставка на смену и со смены корпоративным транспортом", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание (или компенсация) и компенсация проезда"], place: 'Самара', publicationDate: '10.05.2023' },
    // { id: '20', position: 'Штамповщик', minSalary: 47000, salary: 54000, description: 'Зарплата зависит от разряда. Выполнять операции гибка, калибровка, чеканка, на штампах от 250 до 1,5 тонн', requirements: 'Опыт работы на штампах', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Доставка на смену и со смены корпоративным транспортом", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание (или компенсация) и компенсация проезда"], place: 'Самара', publicationDate: '10.05.2023' },
    // { id: '21', position: 'Фрезеровщик', minSalary: 55000, salary: 135000, description: 'Зарплата зависит от разряда. Шлифование и фрезерование деталей', requirements: 'Опыт работы фрезеровщиком. Умение работать на вертикально-фрезерном и горизонтально-фрезерном станках, на плоскошлифовальных станках', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Доставка на смену и со смены корпоративным транспортом", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание (или компенсация) и компенсация проезда"], place: 'Самара', publicationDate: '10.05.2023' },
    // { id: '22', position: 'Электроэрозионист', minSalary: undefined, salary: 89000, description: 'Электроэрозионная обработка поверхностей, полостей, отверстий и пазов в деталях с выверкой и установкой обрабатываемых деталей', requirements: 'Опыт работы электроэрозионистом', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Доставка на смену и со смены корпоративным транспортом", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание (или компенсация) и компенсация проезда"], place: 'Самара', publicationDate: '10.05.2023' },
    // { id: '23', position: 'Токарь универсал', minSalary: undefined, salary: 88000, description: 'Токарная обработка деталей. Проверка на точность токарных станков различной конструкции, универсальных и специальных приспособлений', requirements: 'Опыт работы токарем на универсально токарных станках', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Доставка на смену и со смены корпоративным транспортом", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание (или компенсация) и компенсация проезда"], place: 'Самара', publicationDate: '10.05.2023' },
    // { id: '24', position: 'Слесарь инструментальщик', minSalary: undefined, salary: 78000, description: 'Изготовление и ремонт точных и сложных инструментов и приспособлений; Слесарная обработка и доводка термически не обработанных деталей', requirements: 'Опыт работы слесарем инструментальщиком', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Доставка на смену и со смены корпоративным транспортом", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание (или компенсация) и компенсация проезда"], place: 'Самара', publicationDate: '10.05.2023' },
    // { id: '25', position: 'Шлифовщик', minSalary: 67000, salary: 119000, description: 'Зарплата зависит от разряда. Шлифование наружных цилиндрических поверхностей заготовок, плоских поверхностей заготовок, простых деталей и прутков', requirements: 'Опыт работы шлифовщиком', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Доставка на смену и со смены корпоративным транспортом", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание (или компенсация) и компенсация проезда"], place: 'Самара', publicationDate: '10.05.2023' },
    // { id: '26', position: 'Оператор наладчик токарных станков (ЧПУ)', minSalary: undefined, salary: 80000, description: 'Самостоятельное составление программ на фрезерных станках с ЧПУ модели ГФ2171С5. Подбор инструмента, в зависимости от изготавливаемой продукции. Обработка деталей, согласно требований чертежа', requirements: 'Опыт работы', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание (или компенсация) и компенсация проезда", "График работы 5/2 по 8 часов"], place: 'Самара', publicationDate: '25.08.2023' },
    // { id: '27', position: 'Сварщик', minSalary: undefined, salary: 72000, description: 'Сварка авто компонентов полуавтоматом', requirements: 'Опыт работы на полуавтомате', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание (или компенсация) и компенсация проезда"], place: 'Димитровград', publicationDate: '25.08.2023' },
    // { id: '28', position: 'Водитель погрузчика', minSalary: undefined, salary: 62000, description: 'Работа на дизельном погрузчике. Погрузо-разгрузочные работы на территории предприятия', requirements: 'Опыт работы на погрузчике. Тракторные права', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание (или компенсация) и компенсация проезда"], place: 'Димитровград', publicationDate: '25.08.2023' },
    // { id: '29', position: 'Грузчик-наборщик', minSalary: undefined, salary: 45000, description: 'Разгрузочно-погрузочные работы. Сборка по накладным', requirements: 'Опыт работы с накладными', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Вахтовый автобус"], place: 'Воронеж', publicationDate: '25.08.2023' },
    // { id: '30', position: 'Водитель погрузчика', minSalary: undefined, salary: 80000, description: 'Перемещать шпон от сушильных линий к месту назначения, подвозить и укладывать поддоны на лущильные линии. Осуществлять погрузку отходов в контейнера и на автомашины. Передвижение на погрузчике производить согласно установленной схеме маршрута, подвозить и укладываеть поддоны, осуществлять перевозку грузов', requirements: 'Опыт работы на погрузчике. Тракторные права', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание (или компенсация) и компенсация проезда"], place: 'Галич', publicationDate: '25.08.2023' },
    // { id: '31', position: 'Оператор деревообрабатывающих станков', minSalary: 108000, salary: 168000, description: 'Работа на станках и линиях по деревообработке.', requirements: 'Опыт работы приветствуется, есть обучение', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание (или компенсация) и компенсация проезда"], place: 'Галич', publicationDate: '25.08.2023' },
    // { id: '32', position: 'Сортировщик шпона', minSalary: undefined, salary: 70000, description: 'Обеспечивать беспрерывную подачу шпона и фанеры, сортировка и маркировка шпона и фанеры', requirements: 'Без опыта, есть обучение', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание (или компенсация) и компенсация проезда"], place: 'Галич', publicationDate: '25.08.2023' },
    // { id: '33', position: 'Обрубщик', minSalary: undefined, salary: 135000, description: 'Обработка отливок пригара и прибылей с помощью пневмоинструмента (пневмошлифмашина - ИП 2014, рубильный молоток)', requirements: 'Без опыта, есть обучение', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание (или компенсация) и компенсация проезда"], place: 'Саранск', publicationDate: '25.08.2023' },
    // { id: '34', position: 'Формовщик ручной формовки', minSalary: undefined, salary: 132000, description: 'Изготовление форм, установка стержней в формы', requirements: 'Без опыта, есть обучение', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание (или компенсация) и компенсация проезда"], place: 'Саранск', publicationDate: '25.08.2023' },
    // { id: '35', position: 'Стерженщик ручной формовки', minSalary: undefined, salary: 132000, description: 'Формовка стержней. Изготовление стрежней на ручной оснастке', requirements: 'Без опыта, есть обучение', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Иногородним предоставляется проживание (или компенсация) и компенсация проезда"], place: 'Саранск', publicationDate: '25.08.2023' },
    // { id: '36', position: 'Сварщик аргонщик', minSalary: undefined, salary: 93000, description: 'Варить автокомпоненты. Коллекторы, в простонародье паук', requirements: 'Удостоверение или образование. Опыт работы сварщиком. Ручная электродуговая сварка', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Корпоративный транспорт"], place: 'Тольятти', publicationDate: '25.08.2023' },
    // { id: '37', position: 'Подсобный рабочий (прессовщик)', minSalary: undefined, salary: 45000, description: 'Прессовка втулок', requirements: 'Без опыта, есть обучение', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Корпоративный транспорт"], place: 'Тольятти', publicationDate: '25.08.2023' },
    // { id: '38', position: 'Слесарь МСР', minSalary: undefined, salary: 45000, description: 'Сборка и обработка авто компонентов', requirements: 'Без опыта, есть обучение', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Корпоративный транспорт"], place: 'Тольятти', publicationDate: '25.08.2023' },
    // { id: '39', position: 'Сварщик МКС', minSalary: undefined, salary: 57000, description: 'Сварка деталей на машинах контактной сварки', requirements: 'Опыт работы сварщиком', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Корпоративный транспорт"], place: 'Тольятти', publicationDate: '25.08.2023' },
    // { id: '40', position: 'Штамповщик', minSalary: undefined, salary: 62000, description: 'Работа на прессах, холодная ручная штамповка', requirements: 'Опыт работы штамповщиком', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Корпоративный транспорт"], place: 'Тольятти', publicationDate: '25.08.2023' },
    // { id: '41', position: 'Электромонтер', minSalary: undefined, salary: 86000, description: 'Работа в ремонтной бригаде', requirements: 'Опыт работы по ремонту электрооборудования', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Корпоративный транспорт"], place: 'Тольятти', publicationDate: '25.08.2023' },
    // { id: '42', position: 'Шлифовщик', minSalary: undefined, salary: 55000, description: 'Закладывать тормозные колодки в формы', requirements: 'Опыт работы не требуется', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Корпоративный транспорт"], place: 'Тольятти', publicationDate: '25.08.2023' },
    // { id: '43', position: 'Электрогазосварщик', minSalary: undefined, salary: 84000, description: 'Работа в ремонтной бригаде', requirements: 'Опыт работы сварщиком', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Корпоративный транспорт"], place: 'Тольятти', publicationDate: '25.08.2023' },
    // { id: '44', position: 'Слесарь ремонтник', minSalary: undefined, salary: 76000, description: 'Работа в ремонтной бригаде', requirements: 'Опыт работы', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Корпоративный транспорт"], place: 'Тольятти', publicationDate: '25.08.2023' },
    // { id: '45', position: 'Фрезеровщик', minSalary: undefined, salary: 86000, description: 'Работа в ремонтной бригаде', requirements: 'Опыт работы', conditions: ["Официальное оформление по ТК РФ",  "Стабильные выплаты, 2 раза в месяц, без задержек", "Почасовая оплата труда", "Спецодежда, медкомиссия за счет организации", "Корпоративный транспорт"], place: 'Тольятти', publicationDate: '25.08.2023' },
    // {
    //     id: '1',
    //     position: 'Слесарь МСР',
    //     salary: 40000,
    //     description: 'Сборка электрических розеток (корпуса)',
    //     requirements: 'Рассматриваются мужчины и женщины, без опыта работы',
    //     conditions: [
    //         'Официальное оформление по ТК РФ',
    //         'Стабильные выплаты, без задержек',
    //         'Почасовая оплата труда',
    //         'Спецодежда, медкомиссия за счет организации'
    //     ],
    //     place: 'Тольятти',
    //     publicationDate: '05.06.2023'
    // },
    // {
    //     id: '2',
    //     position: 'Токарь ДИП 200',
    //     salary: 105000,
    //     description: 'Выполнение работ на универсальном токарно-винторезном станке ДИП 200',
    //     requirements: 'Требуется 5-6 разряд. Опыт работы токарем',
    //     conditions: [
    //         'Официальное оформление по ТК РФ',
    //         'Стабильные выплаты, 2 раза в месяц, без задержек',
    //         'Почасовая оплата труда',
    //         'Спецодежда, медкомиссия за счет организации',
    //         'Иногородним предоставляется проживание (или компенсация) и компенсация проезда'
    //     ],
    //     place: 'Воронеж',
    //     publicationDate: '05.06.2023'
    // },
    // {
    //     id: '3',
    //     position: 'Фрезеровщик',
    //     salary: 105000,
    //     description: 'Выполнение работ на универсальном токарно-винторезном станке',
    //     requirements: 'Требуется 5-6 разряд. Опыт работы фрезеровщиком',
    //     conditions: [
    //         'Официальное оформление по ТК РФ',
    //         'Стабильные выплаты, 2 раза в месяц, без задержек',
    //         'Почасовая оплата труда',
    //         'Спецодежда, медкомиссия за счет организации',
    //         'Иногородним предоставляется проживание (или компенсация) и компенсация проезда'
    //     ],
    //     place: 'Воронеж',
    //     publicationDate: '10.05.2022'
    // },
    // {
    //     id: '4',
    //     position: 'Токарь расточник',
    //     salary: 110000,
    //     description: 'Выполнение работ на универсальном токарно-винторезном станке',
    //     requirements: 'Требуется 5-6 разряд. Опыт работы фрезеровщиком',
    //     conditions: [
    //         'Официальное оформление по ТК РФ',
    //         'Стабильные выплаты, 2 раза в месяц, без задержек',
    //         'Почасовая оплата труда',
    //         'Спецодежда, медкомиссия за счет организации',
    //         'Иногородним предоставляется проживание (или компенсация) и компенсация проезда'
    //     ],
    //     place: 'Воронеж',
    //     publicationDate: '10.05.2022'
    // },
    // {
    //     id: '5',
    //     position: 'Токарь расточник',
    //     salary: 95000,
    //     description: 'Обработка деталей средней сложности на универсальных и координатно-расточных станках',
    //     requirements: 'Опыт работы токарем расточником',
    //     conditions: [
    //         'Официальное оформление по ТК РФ',
    //         'Стабильные выплаты, 2 раза в месяц, без задержек',
    //         'Почасовая оплата труда',
    //         'Доставка на смену и со смены корпоративным транспортом',
    //         'Спецодежда, медкомиссия за счет организации',
    //         'Иногородним предоставляется проживание (или компенсация) и компенсация проезда'
    //     ],
    //     place: 'Самара',
    //     publicationDate: '06.05.2023'
    // },
    // {
    //     id: '6',
    //     position: 'Мастер',
    //     salary: 40000,
    //     description: 'Координация персонала, взаимодействие с заказчиками; Заселение иногородних, контроль мест проживания; В подчинение более 100 человек; Умение работать с персоналом (собеседование, обучение, контроль работы); Расстановка по участкам, контроль выхода и работы сотрудников',
    //     requirements: 'Опыт работы с персоналом; Умение работать в режиме многозадачности; Знание ПК на уровне уверенного пользователя; Опыт работы мастером на производстве будет преимуществом',
    //     conditions: [
    //         'Официальное оформление по ТК РФ',
    //         'Стабильные выплаты, 2 раза в месяц, без задержек',
    //     ],
    //     place: 'Саратов',
    //     publicationDate: '06.05.2023'
    // },
    // {
    //     id: '7',
    //     position: 'Наладчик шлифовальных станков',
    //     salary: 118000,
    //     description: '4-6 разряд. Наладка оборудования для шлифования поверхностей колец подшипников с точностью до 0,001мм на круглошлифовальных станках (АGL-125)',
    //     requirements: 'Опыт работы наладчиком',
    //     conditions: [
    //         'Официальное оформление по ТК РФ',
    //         'Стабильные выплаты, 2 раза в месяц, без задержек',
    //         'Почасовая оплата труда',
    //         'Спецодежда, медкомиссия за счет организации',
    //         'Иногородним предоставляется проживание (или компенсация) и компенсация проезда'
    //     ],
    //     place: 'Саратов',
    //     publicationDate: '06.05.2023'
    // },
    // {
    //     id: '8',
    //     position: 'Наладчик автоматов и полуавтоматов (наладчик токарного оборудования)',
    //     salary: 118000,
    //     description: '3-5 разряд. Наладка оборудования для токарной обработки колец подшипников на автоматах 1А 240, полуавтоматах 1Б 265, ЖА 250)',
    //     requirements: 'Опыт работы наладчиком',
    //     conditions: [
    //         'Официальное оформление по ТК РФ',
    //         'Стабильные выплаты, 2 раза в месяц, без задержек',
    //         'Почасовая оплата труда',
    //         'Спецодежда, медкомиссия за счет организации',
    //         'Иногородним предоставляется проживание (или компенсация) и компенсация проезда'
    //     ],
    //     place: 'Саратов',
    //     publicationDate: '06.05.2023'
    // },
    // {
    //     id: '9',
    //     position: 'Наладчик станков и манипуляторов с программным управлением',
    //     salary: 118000,
    //     description: '3-5 разряд. Наладка оборудования для токарной обработки колец подшипников на отделочных станках DF 2/3',
    //     requirements: 'Опыт работы наладчиком',
    //     conditions: [
    //         'Официальное оформление по ТК РФ',
    //         'Стабильные выплаты, 2 раза в месяц, без задержек',
    //         'Почасовая оплата труда',
    //         'Спецодежда, медкомиссия за счет организации',
    //         'Иногородним предоставляется проживание (или компенсация) и компенсация проезда'
    //     ],
    //     place: 'Саратов',
    //     publicationDate: '06.05.2023'
    // },
    // {
    //     id: '10',
    //     position: 'Автоматчик (токарь)',
    //     salary: 86000,
    //     description: '3 разряд. Токарная обработка деталей подшипников на автоматах 1А240, ЖА 250, 1Б265',
    //     requirements: 'Опыт работы токарем',
    //     conditions: [
    //         'Официальное оформление по ТК РФ',
    //         'Стабильные выплаты, 2 раза в месяц, без задержек',
    //         'Почасовая оплата труда',
    //         'Спецодежда, медкомиссия за счет организации',
    //         'Иногородним предоставляется проживание (или компенсация) и компенсация проезда'
    //     ],
    //     place: 'Саратов',
    //     publicationDate: '06.05.2023'
    // },
    // {
    //     id: '11',
    //     position: 'Токарь полуавтоматчик',
    //     salary: 86000,
    //     description: '3 разряд. Токарная обработка деталей подшипников на полуавтоматах 1Б265, КМ 205, КМ 206',
    //     requirements: 'Опыт работы токарем',
    //     conditions: [
    //         'Официальное оформление по ТК РФ',
    //         'Стабильные выплаты, 2 раза в месяц, без задержек',
    //         'Почасовая оплата труда',
    //         'Спецодежда, медкомиссия за счет организации',
    //         'Иногородним предоставляется проживание (или компенсация) и компенсация проезда'
    //     ],
    //     place: 'Саратов',
    //     publicationDate: '06.05.2023'
    // },
    // {
    //     id: '12',
    //     position: 'Токарь',
    //     salary: 105000,
    //     description: '4-6 разряд. Токарная обработка деталей на токарных станках ТВ-320',
    //     requirements: 'Опыт работы токарем',
    //     conditions: [
    //         'Официальное оформление по ТК РФ',
    //         'Стабильные выплаты, 2 раза в месяц, без задержек',
    //         'Почасовая оплата труда',
    //         'Спецодежда, медкомиссия за счет организации',
    //         'Иногородним предоставляется проживание (или компенсация) и компенсация проезда'
    //     ],
    //     place: 'Саратов',
    //     publicationDate: '06.05.2023'
    // },
    // {
    //     id: '13',
    //     position: 'Токарь (универсал)',
    //     salary: 110000,
    //     description: '4-6 разряд. Токарная обработка крупногабаритных изделий на станках МК63 МК64 МК65',
    //     requirements: 'Опыт работы токарем',
    //     conditions: [
    //         'Официальное оформление по ТК РФ',
    //         'Стабильные выплаты, 2 раза в месяц, без задержек',
    //         'Почасовая оплата труда',
    //         'Спецодежда, медкомиссия за счет организации',
    //         'Иногородним предоставляется проживание (или компенсация) и компенсация проезда'
    //     ],
    //     place: 'Саратов',
    //     publicationDate: '06.05.2023'
    // },
    // {
    //     id: '14',
    //     position: 'Фрезеровщик',
    //     salary: 110000,
    //     description: '5-6 разряд.  Фрезеровка корпусных деталей и деталей типа "вал" (пазов, шлицов, зубьев) с применением поворотных столов и делительных приспособлений. Станки типа 6Р 82, 6Т 13',
    //     requirements: 'Опыт работы фрезеровщиком',
    //     conditions: [
    //         'Официальное оформление по ТК РФ',
    //         'Стабильные выплаты, 2 раза в месяц, без задержек',
    //         'Почасовая оплата труда',
    //         'Спецодежда, медкомиссия за счет организации',
    //         'Иногородним предоставляется проживание (или компенсация) и компенсация проезда'
    //     ],
    //     place: 'Саратов',
    //     publicationDate: '06.05.2023'
    // },
    // {
    //     id: '15',
    //     position: 'Слесарь МСР',
    //     minSalary: 42000,
    //     salary: 68000,
    //     description: 'Сборка автомобильных фар и фонарей',
    //     requirements: 'Рассматриваем мужчин и женщин, без опыта работы',
    //     conditions: [
    //         'Официальное оформление по ТК РФ',
    //         'Стабильные выплаты, 2 раза в месяц, без задержек',
    //         'Почасовая оплата труда',
    //         'Спецодежда, медкомиссия за счет организации',
    //         'Иногородним предоставляется проживание (или компенсация) и компенсация проезда'
    //     ],
    //     place: 'Димитровград',
    //     publicationDate: '06.05.2023'
    // },
    // {
    //     id: '16',
    //     position: 'Литейщик',
    //     minSalary: 43000,
    //     salary: 70000,
    //     description: 'Прием деталей от автомата литья пластмассы. Визуальное определение брака литья. Укладка и упаковка деталей. М, Ж без опыта',
    //     requirements: 'Рассматриваем мужчин и женщин, без опыта работы',
    //     conditions: [
    //         'Официальное оформление по ТК РФ',
    //         'Стабильные выплаты, 2 раза в месяц, без задержек',
    //         'Почасовая оплата труда',
    //         'Спецодежда, медкомиссия за счет организации',
    //         'Иногородним предоставляется проживание (или компенсация) и компенсация проезда'
    //     ],
    //     place: 'Димитровград',
    //     publicationDate: '06.05.2023'
    // },
    //
    // {
    //     id: '17',
    //     position: 'Токарь универсал',
    //     salary: 75000,
    //     description: 'Токарная обработка деталей на токарно-винторезном станке 16К25, МК6131. (Протачивать и растачивать в люнете трубчатую заготовку диаметром 140 мм по 10 квалитету точности.)',
    //     requirements: 'Опыт работы токарем на универсальных токарных станках',
    //     conditions: [
    //         'Официальное оформление по ТК РФ',
    //         'Стабильные выплаты, 2 раза в месяц, без задержек',
    //         'Сменный график работы (2/2)',
    //         'Почасовая оплата труда',
    //         'Доставка на смену и со смены корпоративным транспортом',
    //         'Спецодежда, медкомиссия за счет организации',
    //         'Иногородним предоставляется проживание (или компенсация) и компенсация проезда'
    //     ],
    //     place: 'Воронеж',
    //     publicationDate: '10.05.2023'
    // },
    // {
    //     id: '18',
    //     position: 'Маляр',
    //     salary: 65000,
    //     description: 'Окраска металлоконструкций и деталей, краскопультом, жидкими красками.',
    //     requirements: 'Опыт работы маляром по металлу.',
    //     conditions: [
    //         'Официальное оформление по ТК РФ',
    //         'Стабильные выплаты, 2 раза в месяц, без задержек',
    //         'Сменный график работы (2/2)',
    //         'Почасовая оплата труда',
    //         'Доставка на смену и со смены корпоративным транспортом',
    //         'Спецодежда, медкомиссия за счет организации',
    //         'Иногородним предоставляется проживание (или компенсация) и компенсация проезда'
    //     ],
    //     place: 'Воронеж',
    //     publicationDate: '10.05.2022'
    // },
    //
    // // {
    // //     id: '3',
    // //     position: 'Подсобный рабочий',
    // //     salary: 45000,
    // //     description: 'Завешивать детали на конвейерную линию.',
    // //     requirements: 'Опыт работы на производстве.',
    // //     conditions: [
    // //         'Официальное оформление по ТК РФ',
    // //         'Стабильные выплаты, 2 раза в месяц, без задержек',
    // //         'Сменный график работы (2/2)',
    // //         'Почасовая оплата труда',
    // //         'Доставка на смену и со смены корпоративным транспортом',
    // //         'Спецодежда, медкомиссия за счет организации',
    // //     ],
    // //     place: 'Воронеж',
    // //     publicationDate: '10.05.2022'
    // // },
    // // {
    // //     id: '4',
    // //     position: 'Чистильщик металла',
    // //     salary: 65000,
    // //     description: 'Работы по дробеочистке и мойке деталей в камере.',
    // //     requirements: 'Опыт работы по очистке металла (дробеструйщик).',
    // //     conditions: [
    // //         'Официальное оформление по ТК РФ',
    // //         'Стабильные выплаты, 2 раза в месяц, без задержек',
    // //         'Сменный график работы (2/2)',
    // //         'Почасовая оплата труда',
    // //         'Доставка на смену и со смены корпоративным транспортом',
    // //         'Спецодежда, медкомиссия за счет организации',
    // //     ],
    // //     place: 'Воронеж',
    // //     publicationDate: '10.05.2022'
    // // },
    // // {
    // //     id: '5',
    // //     position: 'Грузчик наборщик',
    // //     salary: 45000,
    // //     description: 'Погрузо-разгрузочные работы. Сборка по накладным.',
    // //     requirements: 'Опыт работы на складах, с рохлей.',
    // //     conditions: [
    // //         'Официальное оформление по ТК РФ',
    // //         'Стабильные выплаты, 2 раза в месяц, без задержек',
    // //         'Сменный график работы (2/2)',
    // //         'Почасовая оплата труда',
    // //         'Доставка на смену и со смены корпоративным транспортом',
    // //         'Спецодежда, медкомиссия за счет организации',
    // //         'Иногородним предоставляется проживание (или компенсация) и компенсация проезда'
    // //     ],
    // //     place: 'Воронеж',
    // //     publicationDate: '10.05.2022'
    // // },
    // // {
    // //     id: '6',
    // //     position: 'Токарь расточник',
    // //     salary: 95000,
    // //     description: 'Обработка деталей средней сложности на универсальных и координатно-расточных станках.',
    // //     requirements: 'Опыт работы токарем расточником.',
    // //     conditions: [
    // //         'Официальное оформление по ТК РФ',
    // //         'Стабильные выплаты, 2 раза в месяц, без задержек',
    // //         'Почасовая оплата труда',
    // //         'Доставка на смену и со смены корпоративным транспортом',
    // //         'Спецодежда, медкомиссия за счет организации',
    // //         'Иногородним предоставляется проживание (или компенсация) и компенсация проезда'
    // //     ],
    // //     place: 'Самара',
    // //     publicationDate: '10.05.2022'
    // // },
    //
    // {
    //     id: '19',
    //     position: 'Сверловщик',
    //     salary: 57000,
    //     description: 'Нарезка отверстий разного типа, обработка пазов, уступов, наклонных, горизонтальных и вертикальных поверхностей деталей.',
    //     requirements: 'Опыт работы на сверлильных станках.',
    //     conditions: [
    //         'Официальное оформление по ТК РФ',
    //         'Стабильные выплаты, 2 раза в месяц, без задержек',
    //         'Почасовая оплата труда',
    //         'Доставка на смену и со смены корпоративным транспортом',
    //         'Спецодежда, медкомиссия за счет организации',
    //         'Иногородним предоставляется проживание (или компенсация) и компенсация проезда'
    //     ],
    //     place: 'Самара',
    //     publicationDate: '10.05.2022'
    // },
    // {
    //     id: '20',
    //     position: 'Штамповщик',
    //     minSalary: 47000,
    //     salary: 54000,
    //     description: 'Зарплата зависит от разряда. Выполнять операции гибка, калибровка, чеканка, на штампах от 250 до 1,5 тонн.',
    //     requirements: 'Опыт работы на штампах.',
    //     conditions: [
    //         'Официальное оформление по ТК РФ',
    //         'Стабильные выплаты, 2 раза в месяц, без задержек',
    //         'Почасовая оплата труда',
    //         'Доставка на смену и со смены корпоративным транспортом',
    //         'Спецодежда, медкомиссия за счет организации',
    //         'Иногородним предоставляется проживание (или компенсация) и компенсация проезда'
    //     ],
    //     place: 'Самара',
    //     publicationDate: '10.05.2022'
    // },
    // {
    //     id: '21',
    //     position: 'Фрезеровщик',
    //     minSalary: 55000,
    //     salary: 135000,
    //     description: 'Зарплата зависит от разряда. Шлифование и фрезерование деталей.',
    //     requirements: 'Опыт работы фрезеровщиком. Умение работать на вертикально-фрезерном и горизонтально-фрезерном станках, на плоскошлифовальных станках.',
    //     conditions: [
    //         'Официальное оформление по ТК РФ',
    //         'Стабильные выплаты, 2 раза в месяц, без задержек',
    //         'Почасовая оплата труда',
    //         'Доставка на смену и со смены корпоративным транспортом',
    //         'Спецодежда, медкомиссия за счет организации',
    //         'Иногородним предоставляется проживание (или компенсация) и компенсация проезда'
    //     ],
    //     place: 'Самара',
    //     publicationDate: '10.05.2022'
    // },
    // {
    //     id: '22',
    //     position: 'Электроэрозионист',
    //     salary: 89000,
    //     description: 'Электроэрозионная обработка поверхностей, полостей, отверстий и пазов в деталях с выверкой и установкой обрабатываемых деталей.',
    //     requirements: 'Опыт работы электроэрозионистом.',
    //     conditions: [
    //         'Официальное оформление по ТК РФ',
    //         'Стабильные выплаты, 2 раза в месяц, без задержек',
    //         'Почасовая оплата труда',
    //         'Доставка на смену и со смены корпоративным транспортом',
    //         'Спецодежда, медкомиссия за счет организации',
    //         'Иногородним предоставляется проживание (или компенсация) и компенсация проезда'
    //     ],
    //     place: 'Самара',
    //     publicationDate: '10.05.2022'
    // },
    // {
    //     id: '23',
    //     position: 'Токарь универсал',
    //     salary: 88000,
    //     description: 'Токарная обработка деталей. Проверка на точность токарных станков различной конструкции, универсальных и специальных приспособлений.',
    //     requirements: 'Опыт работы токарем на универсально токарных станках.',
    //     conditions: [
    //         'Официальное оформление по ТК РФ',
    //         'Стабильные выплаты, 2 раза в месяц, без задержек',
    //         'Почасовая оплата труда',
    //         'Доставка на смену и со смены корпоративным транспортом',
    //         'Спецодежда, медкомиссия за счет организации',
    //         'Иногородним предоставляется проживание (или компенсация) и компенсация проезда'
    //     ],
    //     place: 'Самара',
    //     publicationDate: '10.05.2022'
    // },
    // {
    //     id: '24',
    //     position: 'Слесарь инструментальщик',
    //     salary: 78000,
    //     description: 'Изготовление и ремонт точных и сложных инструментов и приспособлений; Слесарная обработка и доводка термически не обработанных деталей.',
    //     requirements: 'Опыт работы слесарем инструментальщиком.',
    //     conditions: [
    //         'Официальное оформление по ТК РФ',
    //         'Стабильные выплаты, 2 раза в месяц, без задержек',
    //         'Почасовая оплата труда',
    //         'Доставка на смену и со смены корпоративным транспортом',
    //         'Спецодежда, медкомиссия за счет организации',
    //         'Иногородним предоставляется проживание (или компенсация) и компенсация проезда'
    //     ],
    //     place: 'Самара',
    //     publicationDate: '10.05.2022'
    // },
    // {
    //     id: '25',
    //     position: 'Шлифовщик',
    //     minSalary: 67000,
    //     salary: 119000,
    //     description: 'Зарплата зависит от разряда. Шлифование наружных цилиндрических поверхностей заготовок, плоских поверхностей заготовок, простых деталей и прутков.',
    //     requirements: 'Опыт работы шлифовщиком.',
    //     conditions: [
    //         'Официальное оформление по ТК РФ',
    //         'Стабильные выплаты, 2 раза в месяц, без задержек',
    //         'Почасовая оплата труда',
    //         'Доставка на смену и со смены корпоративным транспортом',
    //         'Спецодежда, медкомиссия за счет организации',
    //         'Иногородним предоставляется проживание (или компенсация) и компенсация проезда'
    //     ],
    //     place: 'Самара',
    //     publicationDate: '10.05.2022'
    // },
]